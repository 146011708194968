<template>
	<div class="ac_search_list_item">
		<div class="ac_search_list_top">
			<div class="ac_search_list_item_img pointer" @click="gotoDetail()">
				<img :src="item.pic" />
			</div>
			<div class="ac_search_list_item_info">
				<div class="ac_search_list_item_top">
					<div
						class="ac_search_list_item_status ac_search_list_item_status_1"
						v-if="item.startStatus == 0"
					>
						<div
							class="ac_search_list_item_status_point ac_search_list_item_status_point_1"
						>
						</div>

						<div
							class="ac_search_list_item_status_text ac_search_list_item_status_text_1"
						>
							活动尚未开始
						</div>
					</div>
					<div
						class="ac_search_list_item_status ac_search_list_item_status_2"
						v-if="item.startStatus == 1"
					>
						<div
							class="ac_search_list_item_status_point ac_search_list_item_status_point_2"
						>
						</div>

						<div
							class="ac_search_list_item_status_text ac_search_list_item_status_text_2"
						>
							活动正在进行中
						</div>
					</div>
					<div
						class="ac_search_list_item_status ac_search_list_item_status_3"
						v-if="item.startStatus == 2"
					>
						<div
							class="ac_search_list_item_status_point ac_search_list_item_status_point_3"
						>
						</div>

						<div
							class="ac_search_list_item_status_text ac_search_list_item_status_text_3"
						>
							活动已结束
						</div>
					</div>
				</div>
				<div class="ac_search_list_item_title">
					{{ item.title }}
				</div>
				<div class="ac_search_list_item_area">
					<div class="ac_search_list_item_area_item">
						<img src="https://zy.metaera.media/assets/images/active/59.png" />
						<span
							>{{ formatDate(item.start_time) }} 至
							{{ formatDate(item.end_time) }} {{ item.timeZone }}</span
						>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "",
	props: {
		item: {},
		index: 0,
		type: 0, //默认主办方；1、参加的活动
	},
	created() {},
	data() {
		return {};
	},
	methods: {
		showUrl(url) {
			if (url == undefined || url.length == 0) {
				return;
			}
			window.open(url, "");
		},
		close() {
			this.$emit("close");
		},
		refund() {
			this.$emit("refund", this.item);
		},
		cancelOrder() {
			this.$emit("cancelOrder", this.item);
		},
		continueOrder() {
			this.$emit("continueOrder", this.item);
		},
		gotoDetail() {
			this.$emit("gotoDetail", this.item);
		},
		gotoEdit() {
			this.$emit("gotoEdit", this.item.id);
		},
		gotoDel() {
			this.$emit("gotoDel", this.item.id);
		},
		formatDate(dateString) {
			const date = new Date(dateString);
			return date.toLocaleString("zh-CN", {
				year: "numeric",
				month: "2-digit",
				day: "2-digit",
				hour: "2-digit",
				minute: "2-digit",
				hour12: false,
			});
		},
	},
	watch: {},
};
</script>

<style scoped>
.ac_search_list_item {
	margin-bottom: 30px;
}
.ac_search_list_top {
	display: flex;
	background: #fafafa;
	border-radius: 10px;
	min-height: 140px;
}

.ac_search_list_item_img {
	width: 30%;
	aspect-ratio: 16 / 9;
	border-radius: 20px;
	overflow: hidden;
	align-content: center;
	align-items: center;
}

.ac_search_list_item_img img {
	width: 100%;
	aspect-ratio: 16 / 9;
	border-radius: 20px;
	opacity: 1;
	object-fit: cover;
	overflow: hidden;
}

.ac_search_list_item_info {
	width: 70%;
	flex: 1;
	display: flex;
	flex-direction: column;
}
.ac_search_list_item_top {
	display: flex;
	justify-content: space-between;
	margin-top: 0px;
}

.ac_search_list_item_aprove_status {
	margin-right: 20px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 16px;
	font-weight: normal;
	line-height: 16px;
	display: flex;
	align-items: center;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
}
.ac_search_list_item_aprove_status span {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 16px;
	font-weight: normal;
	line-height: 16px;
	margin-left: 20px;
}
.ac_search_list_item_aprove_status_1 {
	color: #3ecd8a;
}
.ac_search_list_item_aprove_status_2 {
	color: #f67348;
}
.ac_search_list_item_aprove_status_3 {
	color: #ea0000;
}
.ac_search_list_item_aprove_status_4 {
	color: #3ecd8a;
	display: none;
}

.ac_search_list_item_status {
	margin-left: 20px;
	margin-top: 20px;
	height: 26px;
	border-radius: 7px;
	display: flex;
	align-items: center;
	text-align: center;
	justify-content: space-between;
}

.ac_search_list_item_status_1 {
	/* 主要色/主要色 */
	background: rgba(2, 86, 255, 0.1);
}
.ac_search_list_item_status_2 {
	/* 主要色/主要色 */
	background: rgba(62, 205, 138, 0.1);
}
.ac_search_list_item_status_3 {
	/* 主要色/主要色 */
	background: rgba(170, 170, 170, 0.1);
}

.ac_search_list_item_status_point {
	margin-left: 10px;
	margin-right: 10px;
	width: 8px;
	height: 8px;
	border-radius: 136px;
	opacity: 1;
}
.ac_search_list_item_status_point_1 {
	background: linear-gradient(
		45deg,
		#9945ff,
		#8752f3,
		#5497d5,
		#43b4ca,
		#28e0b9,
		#19fb9b
	);
}
.ac_search_list_item_status_point_2 {
	background: #3ecd8a;
}
.ac_search_list_item_status_point_3 {
	background: #333333;
}

.ac_search_list_item_status_text {
	margin-right: 10px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 12px;
	font-weight: normal;
	line-height: 12px;
	/* text-transform: uppercase; */
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
}

.ac_search_list_item_status_text_1 {
	/* 主要色/主要色 */
	color: #0256ff;
}

.ac_search_list_item_status_text_2 {
	color: #3ecd8a;
}

.ac_search_list_item_status_text_3 {
	color: #333333;
}

.ac_search_list_item_title {
	margin-left: 20px;
	margin-right: 20px;
	margin-top: 8px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 16px;
	font-weight: normal;
	line-height: 20px;
	display: flex;
	align-items: center;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 黑色主色（字段一级） */
	color: #333333;

	text-overflow: -o-ellipsis-lastline;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}

.ac_search_list_item_area {
	margin-top: clamp(10px, calc(100vw * 10 / 1280), 16px);
	margin-left: 20px;
	margin-right: 20px;
	display: block;
}

.ac_search_list_item_area_item {
	display: flex;
	margin-right: 40px;
	align-content: center;
	align-items: center;
	margin-top: 0px;
}

.ac_search_list_item_area_item img {
	width: 16px;
	height: auto;
	opacity: 1;
}

.ac_search_list_item_area_item span {
	margin-left: 10px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 12px;
	font-weight: normal;
	line-height: 14px;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 正文色/正文辅助色 */
	color: #767676;
}

.ac_search_list_item_bottom {
	margin-left: 20px;
	margin-right: 40px;
	margin-top: auto;
	margin-bottom: 20px;
	display: flex;
	height: 40px;
	flex: 0 1 auto;
	justify-content: space-between;
}

.ac_search_list_item_bottom_time,
.ac_search_list_item_bottom_time span {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 16px;
	font-weight: normal;
	line-height: 16px;
	display: flex;
	align-items: center;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 黑色主色（字段一级） */
	color: #333333;
}

.ac_search_list_item_bottom_option {
	display: flex;
}

.ac_search_list_item_bottom_option_item {
	display: flex;
	margin-left: 30px;
	align-items: center;
}

.ac_search_list_item_bottom_option_item img {
	width: 16px;
	height: 16px;
	opacity: 1;
}
.ac_search_list_item_bottom_option_item span {
	margin-left: 10px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 16px;
	font-weight: normal;
	line-height: 16px;
	display: flex;
	align-items: center;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 333 */
	color: #333333;
}

.ac_search_list_bottom {
	margin-top: 10px;
	padding-left: 30px;
	height: 60px;
	border-radius: 10px;
	opacity: 1;

	background: #fafafa;

	box-sizing: border-box;
	/* 错误色/错误色 */
	border: 1px solid #ea0000;

	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 16px;
	font-weight: normal;
	line-height: 16px;
	display: flex;
	align-items: center;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 错误色/错误色 */
	color: #ea0000;
}

.ac_search_list_item_bottom_ticket {
	height: 40px;
	width: 100%;
	display: flex;
	justify-content: space-between;
}
.ac_search_list_item_bottom_ticket_left {
	display: flex;
}
.ac_search_list_item_bottom_ticket_left_item,
.ac_search_list_item_bottom_ticket_left_item span {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: clamp(12px, calc(100vw * 14 / 1280), 16px);
	font-weight: normal;
	line-height: 16px;
	display: flex;
	align-items: end;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 描边色/描边色辅助 */
	color: #333333;
}
.ac_search_list_item_bottom_ticket_left_item {
	margin-right: clamp(10px, calc(100vw * 15 / 1280), 30px);
}

.ac_search_list_item_bottom_ticket_right {
	display: flex;
	width: calc(100vw * 260 / 1280);
	min-width: 240px;
	/* max-width: 300px; */
	justify-content: flex-end; /* 将内容居右对齐 */
}

.ac_search_list_item_bottom_ticket_right_quit {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: clamp(12px, calc(100vw * 16 / 1280), 20px);
	font-weight: normal;
	line-height: 16px;
	display: flex;
	align-items: center;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 描边色/描边色辅助 */
	color: #aaaaaa;
}

.ac_search_list_item_bottom_ticket_right_d {
	margin-left: clamp(16px, calc(100vw * 20 / 1280), 30px);
	display: flex;
}
.ac_search_list_item_bottom_ticket_right_d_text,
.ac_search_list_item_bottom_ticket_right_d_text span {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: clamp(12px, calc(100vw * 16 / 1280), 20px);
	font-weight: normal;
	line-height: 16px;
	display: flex;
	align-items: end;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 黑色主色（字段一级） */
	color: #333333;
}
.ac_search_list_item_bottom_ticket_right_d_num {
	height: 42px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: clamp(16px, calc(100vw * 30 / 1280), 30px);
	font-weight: 500;
	line-height: clamp(16px, calc(100vw * 30 / 1280), 30px);
	display: flex;
	align-items: end;

	font-variation-settings: "opsz" auto;
	/* 主要色/主要色 */
	color: #0256ff;
}
</style>

<style></style>
<style scoped>
@media screen and (max-width: 900px) {
	.ac_search_list_top {
		display: block;
		background: #fafafa;
		border-radius: 10px;
		min-height: 120px;
	}

	.ac_search_list_item_img {
		width: 100%;
	}

	.ac_search_list_item_info {
		width: 100%;
	}

	.ac_search_list_item_top {
		margin-top: 10px;
	}
	.ac_search_list_item_status {
		margin-top: 0px;
	}
	.ac_search_list_item_status_text {
		margin-right: 6px;
		font-size: 10px;
	}

	.ac_search_list_item_aprove_status {
		margin-right: 10px;
	}
	.ac_search_list_item_aprove_status span {
		font-size: 12px;
	}

	.ac_search_list_item_area {
		margin-top: clamp(10px, calc(100vw * 10 / 1280), 26px);
		margin-left: 20px;
		margin-right: 20px;
		display: block;
	}
	.ac_search_list_item_area_item {
		margin-top: 10px;
	}
	.ac_search_list_item_bottom {
		margin-left: 20px;
		margin-right: 0px;
		margin-bottom: 10px;
		margin-top: 20px;
		height: 60px;
	}

	.ac_search_list_item_bottom_ticket {
		height: auto;
		width: 100%;
		display: block;
	}
	.ac_search_list_item_bottom_ticket_right {
		display: flex;
		width: 100%;
		min-width: 240px;
	}
}
</style>
